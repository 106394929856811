import * as React from "react";
import { useState } from "react";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import {
  Box,
  FormLabel,
  Flex,
  Input,
  Heading,
  Textarea,
  Text,
} from "@chakra-ui/react";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/100.css";
import "@fontsource/work-sans/200.css";
import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/800.css";
import "@fontsource/work-sans/900.css";
import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/square-peg";
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
export default function Contact({ location, updateModalShouldBeVisible }) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contactform",
        ...data,
      }),
    })
      .then((response) => {
        setSuccessMessage("Thank you for your message!");
        reset();
      })
      .catch((error) => console.log(error));
    e.preventDefault();
  };

  const [successMessage, setSuccessMessage] = useState("");

  return (
    <Layout bgColor="white" path={location.pathname}>
      <Flex
        id="contactsection"
        direction="column"
        color="grey.700"
        fontFamily="Work Sans"
        w={{ base: "300px", sm: "400px", md: "600px" }}
        margin="auto"
        pt="30px"
        pb="20px"
      >
        <Heading as="h1" fontSize="28px" color="green.500" mb="2">
          Contact
        </Heading>
        <Text fontWeight="400" fontSize="16px">
          {" "}
          To share any of your questions or comments please write using the
          comment box below or directly at:
        </Text>
        <Text
          display="inline"
          color="green.600"
          fontWeight="500"
          fontSize="16px"
          as="a"
          href="mailto:mciandbeyond@gmail.com"
          pt="2"
          pb="8"
        >
          {" "}
          mciandbeyond@gmail.com
        </Text>
        {/* <Text fontWeight="400" fontSize="16px" py="2">
          {" "}
          or write to us here:
        </Text> */}
        <form
          name="contactform"
          method="POST"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input type="hidden" name="form-name" value="contactform" />
          {/* <Input
          type="text"
          className="visually-hidden"
          name="bot-field"
          {...register("bot-field")}
        /> */}
          <Box py="2">
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              name="name"
              id="name"
              type="text"
              placeholder="Name"
              {...register("name", {
                required: "please include your name",
              })}
            />
            {errors.name && (
              <Text color="red" pl="1" py="1">
                {errors.name.message}
              </Text>
            )}
          </Box>
          <Box py="2">
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              name="email"
              id="email"
              type="text"
              placeholder="Email address"
              {...register("email", {
                required: "please include your email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "this is not a valid e-mail address",
                },
              })}
            />
            {errors.email && (
              <Text color="red" pl="1" py="1">
                {errors.email.message}
              </Text>
            )}
          </Box>
          {/* <Box py="2">
            <FormLabel htmlFor="subject">Subject</FormLabel>
            <Select id="subject" name="subject">
              <option>General questions</option>
              <option>Option 2</option>
              <option>Option 3</option>
              <option>Option 4</option>
            </Select>
          </Box> */}
          <Box py="2">
            <FormLabel className="visually-hidden" htmlFor="message">
              Questions or comments
            </FormLabel>
            <Textarea
              id="message"
              name="message"
              type="text"
              maxH="300px"
              placeholder="Questions or comments"
              {...register("message", {
                required: "please include your message",
              })}
            />
            {errors.message && (
              <Text color="red" pl="1" py="1">
                {errors.message.message}
              </Text>
            )}
          </Box>

          <Input
            fontFamily="Montserrat"
            color="white"
            bg="green.400"
            type="submit"
            value="SEND"
            fontSize="24px"
            fontWeight="600"
            w="150px"
            my="1"
            ml="1"
            rounded="0"
          />
        </form>
        <Text px="1" py="3" fontWeight="500" fontSize="18px" color="green">
          {successMessage}
        </Text>
      </Flex>
    </Layout>
  );
}
